function getConfig() {
    if (window.location.hostname === "localhost") {
        return {
            //ServiceWorkerben is át kell írni az sajnos ezt a file-t nem éri el
            DOMAIN: "https://react.netkorzo.com",
            IMG_PREFIX: "https://react.netkorzo.com",
            API_URL: "https://react.netkorzo.com/rest/v1/",
            POST_HEADER: { headers: { "Content-Type": "application/json" } },
            IDB_NAME: "nkoDb",
            IDB_VERSION: 1,
            APP_VERSION: "v1.0",
            //push notification
            USE_FIREBASE: false,
            FIREBASE_CONFIG: {
                apiKey: "AIzaSyDJ692CzLOGU5F2l0louzWUfiW_2Wy54oc",
                authDomain: "nko-notification.firebaseapp.com",
                projectId: "nko-notification",
                storageBucket: "nko-notification.appspot.com",
                messagingSenderId: "553113194616",
                appId: "1:553113194616:web:326b971e8373f6c71fdd8d",
            },
            FIREBASE_VAPIDKEY: {
                vapidKey:
                    "BAU7CXfafPjs_glCbkPp78-o1hSOQSxJG1cbCPbielJe08H3KLClXW9DOxL89DmhfaP8dVHvNFfPanXPdQ8u6g8",
            },
            //offline működés
            USE_SERVICE_WORKER: true,
            OFFLINE_IDB_TABLES: [], //azok a táblák amiket lehet offline módosítani
        };
    }

    return {
        //ServiceWorkerben is át kell írni az sajnos ezt a file-t nem éri el
        DOMAIN: "https://react.netkorzo.com",
        IMG_PREFIX: "https://react.netkorzo.com",
        API_URL: "https://react.netkorzo.com/rest/v1/",
        POST_HEADER: { headers: { "Content-Type": "application/json" } },
        IDB_NAME: "nkoDb",
        IDB_VERSION: 1,
        APP_VERSION: "v1.1.0",
        
        //push notification
        USE_FIREBASE: false,
        FIREBASE_CONFIG: {
            apiKey: "AIzaSyDJ692CzLOGU5F2l0louzWUfiW_2Wy54oc",
            authDomain: "nko-notification.firebaseapp.com",
            projectId: "nko-notification",
            storageBucket: "nko-notification.appspot.com",
            messagingSenderId: "553113194616",
            appId: "1:553113194616:web:326b971e8373f6c71fdd8d",
        },
        FIREBASE_VAPIDKEY: {
            vapidKey:
                "BAU7CXfafPjs_glCbkPp78-o1hSOQSxJG1cbCPbielJe08H3KLClXW9DOxL89DmhfaP8dVHvNFfPanXPdQ8u6g8",
        },
        //offline működés
        USE_SERVICE_WORKER: true,
        OFFLINE_IDB_TABLES: [], //azok a táblák amiket lehet offline módosítani
    };
}

const config = getConfig();
export default config;
