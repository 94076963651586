import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import * as serviceWorker from "./serviceWorker";
import config from "./core/config";

import App from "./App";
//css
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/css/site.css";
//FontAwesome 6
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, far, fab);

const render = () => {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={Store}>
                    <App />
                </Provider>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
    );
};
render();

//Store.subscribe(render);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * SERVICE WORKER cacheléshez és offline működéshez kell.
 * CSAK HTTPS-en megy
 * Ha használjuk, akkor minden buildelés után be kell rakni a build/service-worker.js-be:
 * Ez arra kell, hogy frissüljenek a cachelt fileok
 *
 * self.addEventListener('install', function() {
 *     self.skipWaiting();
 *  });
 *
 */

if (config.USE_SERVICE_WORKER) {
    serviceWorker.register({
        onUpdate: registration => {
            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                console.log('asd');
            }
            window.location.reload();
        }
        /*onUpdate: (e) => {
            const { waiting: { postMessage = null } = {}, update } = e || {};
            if (postMessage) {
                postMessage({ type: 'SKIP_WAITING' });
            }
            update().then(() => {
                window.location.reload();
            });
        },*/
    });
}

/**
 * FIREBASE PUSH NOTIFICATION
 * CSAK HTTPS-en megy
 *
 * Ez csak egy példa.
 * új projekt létrehozása
 * @link https://console.firebase.google.com/
 * */

if (config.USE_FIREBASE) {
    const firebaseApp = initializeApp(config.FIREBASE_CONFIG);
    const messaging = getMessaging(firebaseApp);

    getToken(messaging, config.FIREBASE_VAPIDKEY)
        .then((currentToken) => {
            if (currentToken) {
                Store.dispatch({
                    type: "firebase_subscribe_token",
                    value: currentToken,
                });
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });
    onMessage(messaging, (payload) => {
        console.log("message:", payload);
    });
    window.addEventListener("online", async (e) => {
        console.log("online");
    });
}
