import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {siteActions} from "../store/SiteSlice";
import useAnalyticsEventTracker from "../components/GoogleAnalytics/useAnalyticsEventTrigger";

const Index = props => {
    const gaEventTracker = useAnalyticsEventTracker('pwaInstall');
    const plants = useSelector(state => state.plants);
    const [error, setError] = useState('');
    const [selectedPlant, setSelectedPlant] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref = useRef();

    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [isInPwa, setIsInPwa] = useState(false);

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        // if are standalone android OR safari
        if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true)) {
            window.addEventListener("beforeinstallprompt", handler);
        } else {
            setIsInPwa(true);
        }
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const installPwa = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
        promptInstall.userChoice.then(function (choiceResult) {
            if (choiceResult.outcome != 'dismissed') {
                setSupportsPWA(false);
                gaEventTracker('install');
            }
        });
    };

    const selectPlant = (e) => {
        let value = e.target.value;
        setSelectedPlant(value);
    }

    const calculate = () => {
        const inputVal = Number(ref.current.value);
        if (isNaN(inputVal) || inputVal == 0) {//ha nem szám akkor hiba
            setError('Kérjük számot írjon be!');
            return;
        }
        dispatch(siteActions.amount(inputVal));

        if (selectedPlant) {
            dispatch(siteActions.amount(inputVal));
            navigate('/calculated/' + selectedPlant);
        }
    }
    const installationGuide = () => {
        navigate('/installation-guide');
    }
    const removeError = () => {
        setError('');
    }

    return <div className={'text-center calculator'}>

        <select name="plants" id="" placeholder={"valami"} onChange={selectPlant} defaultValue={''}>
            <option className={"select-prompt"} value="" disabled>Válassza ki a kultúrát!</option>

            {plants.map(plant => <option key={plant.id} value={plant.id}>{plant.name}</option>)}
        </select>

        <input className="bordered-input" ref={ref} type="number" onFocus={removeError} placeholder="Szemtermés t/ha"/>
        <p>{error}</p>
        <button type="button" className="button" onClick={calculate}>Számítás</button>
        {!isInPwa && <div>
            {supportsPWA && <button id="installApp" className="link" onClick={installPwa}>Telepítés</button>}
            {!supportsPWA && <button className="link" onClick={installationGuide}>Telepítés</button>}
        </div>
        }
    </div>
}
export default Index;