import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const Calculated = () => {
    let {type} = useParams();
    const amount = useSelector(state => state.amount);
    const plantNames = useSelector(state=>state.plantNames);

    const navigate = useNavigate();

    useEffect(()=>{
        if (amount === 0){
            navigate('/');
        }
    },[amount,navigate]);

    const rootCrop = {
        maize:        3.0,
        wheat:        1.4,
        autumnBarley: 1.4,
        springBarley: 1.4,
        rye:          1.4,
        oats:         1.4,
        sunflower:    4.7,
        rape:         1.8
    }
    const sideProductMultiplier = {
        maize:        {min:1   ,  max: 1.2  },
        wheat:        {min:1.2 ,  max: 1.5  },
        autumnBarley: {min:1.8 ,  max: 2.3  },
        springBarley: {min:1.6 ,  max: 1.8  },
        rye:          {min:2   ,  max: 2.5  },
        oats:         {min:1.7 ,  max: 2    },
        sunflower:    {min:2.2 ,  max: 3    },
        rape:         {min:1.6 ,  max: 2.5  }
    }
    const rootNutrientContent = {//a táblázatban % ban van megadva
        maize:        {N:0.0100,  PO:0.0081 , KO:0.0048 },
        wheat:        {N:0.0115,  PO:0.0137 , KO:0.0094 },
        autumnBarley: {N:0.0115,  PO:0.0137 , KO:0.0094 },
        springBarley: {N:0.0115,  PO:0.0137 , KO:0.0094 },
        rye:          {N:0.0115,  PO:0.0137 , KO:0.0094 },
        oats:         {N:0.0115,  PO:0.0137 , KO:0.0094 },
        sunflower:    {N:0.0097,  PO:0.0088 , KO:0.0063 },
        rape:         {N:0.0180,  PO:0.0107 , KO:0.0100 }
    }
    const strawNutrientContent = {
        maize:        {N:0.0060,  PO:0.0020 , KO:0.0130 },
        wheat:        {N:0.0050,  PO:0.0020 , KO:0.0100 },
        autumnBarley: {N:0.0050,  PO:0.0020 , KO:0.0120 },
        springBarley: {N:0.0050,  PO:0.0020 , KO:0.0120 },
        rye:          {N:0.0050,  PO:0.0020 , KO:0.0090 },
        oats:         {N:0.0060,  PO:0.0020 , KO:0.0150 },
        sunflower:    {N:0.0050,  PO:0.0030 , KO:0.0350 },
        rape:         {N:0.0050,  PO:0.0020 , KO:0.0110 }
    }

    //              $C7        *1000*       $B18                   +               $D7                  *1000*     $E18
    const NMin = Math.round(rootCrop[type]*1000*rootNutrientContent[type].N   +  (sideProductMultiplier[type].min*amount)*1000*strawNutrientContent[type].N);
    //              $C7        *1000*       $B18                   +               $E7                  *1000*     $E18
    const NMax = Math.round(rootCrop[type]*1000*rootNutrientContent[type].N   +  (sideProductMultiplier[type].max*amount)*1000*strawNutrientContent[type].N);

    //              $C7         *1000*      $C18                   +                         $D7            *1000*       $F18
    const POMin = Math.round(rootCrop[type]*1000*rootNutrientContent[type].PO +  (sideProductMultiplier[type].min*amount)*1000*strawNutrientContent[type].PO);
    //              $C7         *1000*      $C18                   +                         $E7            *1000*       $F18
    const POMax = Math.round(rootCrop[type]*1000*rootNutrientContent[type].PO +  (sideProductMultiplier[type].max*amount)*1000*strawNutrientContent[type].PO);

    //              $C7         *1000*      $D18                   +                         $D7            *1000*       $G18
    const KOMin = Math.round(rootCrop[type]*1000*rootNutrientContent[type].KO +  (sideProductMultiplier[type].min*amount)*1000*strawNutrientContent[type].KO);
    //              $C7         *1000*      $C18                   +                         $E7            *1000*       $G18
    const KOMax = Math.round(rootCrop[type]*1000*rootNutrientContent[type].KO +  (sideProductMultiplier[type].max*amount)*1000*strawNutrientContent[type].KO);

    const OrganicMaterialMin = Math.round((sideProductMultiplier[type].min * amount)+rootCrop[type]);
    const OrganicMaterialMax = Math.round((sideProductMultiplier[type].max * amount)+rootCrop[type]);

    return <>
        <p className="text-start calculated-text">{amount} t/ha {plantNames[type]}</p>
        <p className="text-start calculated-text">A gyökér- és szármaradványokban felhalmozott tápanyagmennyiség:</p>
        <div className="component-result">
            <div>
                <div className="box-title">N</div>
                <div className="box-content box-right box-small-title">MIN</div>
                <div className="box-content box-small-title">MAX</div>
                <div className="box-content box-right min">{NMin}</div>
                <div className="box-content max">{NMax}</div>
            </div>
        </div>
        <div className="component-result">
            <div>
                <div className="box-title">P<sub>2</sub>O<sub>5</sub></div>
                <div className="box-content box-right box-small-title">MIN</div>
                <div className="box-content box-small-title">MAX</div>
                <div className="box-content box-right min">{POMin}</div>
                <div className="box-content max">{POMax}</div>
            </div>
        </div>
        <div className="component-result">
            <div>
                <div className="box-title">K<sub>2</sub>O</div>
                <div className="box-content box-right box-small-title">MIN</div>
                <div className="box-content box-small-title">MAX</div>
                <div className="box-content box-right min">{KOMin}</div>
                <div className="box-content max">{KOMax}</div>
            </div>
        </div>
        <div className="component-result">
            <div>
                <div className="box-title">Szervesanyag</div>
                <small className="organic-material-subtitle">Szár- és gyökérmaradványok teljes mennyisége</small>
                <div className="box-content box-right box-small-title">MIN</div>
                <div className="box-content box-small-title">MAX</div>
                <div className="box-content box-right min">{OrganicMaterialMin}</div>
                <div className="box-content max">{OrganicMaterialMax}</div>
            </div>
        </div>
        <div className="text-start">
            * A kiszámolt értékek kg/ha-ban értendőek<br/>
            ** A szervesanyag mennyisége t/ha-ban. <br/>
            *** Az adatok tájékoztató jellegűek
        </div>
        <div>
            <Link className="button" to={'/'}>Új számolás indítása</Link>
        </div>
        <div>
            <a className="button contact-btn" href={"https://phylazonit.hu/tanacsadoi-halozat/"}>Kapcsolatfelvétel</a>
        </div>
    </>;
}
export default Calculated;