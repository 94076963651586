import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Default = (props) => {
    return (
        <div className="container">
            <Header/>
            <div className="custom-container">
                <Outlet />
            </div>
        </div>
    );
};
export default Default;
