import React, {useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {siteActions} from "../store/SiteSlice";

const Calculator = () => {
    let params = useParams();
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const plantNames = useSelector(state=>state.plantNames);
    const dispatch = useDispatch();
    const ref = useRef();

    const calculate = () => {
        const inputVal = Number(ref.current.value);
        if(isNaN(inputVal)){//ha nem szám akkor hiba
            setError('Kérjük számot írjon be!');
            return;
        }
        dispatch(siteActions.amount(inputVal));
        navigate('/calculated/'+params.type);

    }
    const removeError = ()=>{
        setError('');
    }
    return <div>
        <h2>{plantNames[params.type]}</h2>
        <input className="bordered-input" ref={ref} type="number" onFocus={removeError} placeholder="t/ha"/>
        <p>{error}</p>
        <button type="button" className="button" onClick={calculate}>Számítás</button>
    </div>;
}
export default Calculator;