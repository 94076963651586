import React from "react";
import {Link} from "react-router-dom";

const InstallationGuide = () => {
    return <div>
        <h2 style={{textAlign:'left'}}>Telepítés</h2>
        <p style={{textAlign:'left'}}><strong>Android</strong>: Nyissa meg Google Chrome böngészőben az oldat, majd kattintson a telepítés gombra.</p>
        <p style={{textAlign:'left'}}><strong>IOS</strong>: Nyissa meg Safari böngészőben az oldalt, majd kattintson a megosztás gombra a böngészősáv közepén.<br/>Gördítsen lejebb a "Főképernyőhöz adás" opcióig és kattintson a "Hozzáadás" gombra.</p>
        <div>
            <Link className="button" to={'/'}>Vissza a főoldalra</Link>
        </div>
    </div>;
}
export default InstallationGuide;