import React, {useEffect} from "react";
import Index from "./pages/Index";
import {Route, Routes} from "react-router-dom";
import Default from "./pages/layouts/Default";
import Calculator from "./pages/Calculator";
import Calculated from "./pages/Calculated";
import ReactGA from "react-ga";
import InstallationGuide from "./pages/InstallationGuide";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_GA_CODE;
if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
}

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (<>
            <div className="background"></div>
            <div className="App">
                <Routes>
                    <Route element={<Default/>}>
                        <Route path="/calculate/:type" element={<Calculator/>}/>
                        <Route path="/calculated/:type" element={<Calculated/>}/>
                        <Route path="/installation-guide" element={<InstallationGuide/>}/>
                        <Route path="*" element={<Index/>}/>

                    </Route>
                </Routes>
            </div>
        </>
    );

}

export default App;
