import React from "react";
import logo from "../../style/img/phylazonit_ng.png";
import {Link} from "react-router-dom";

const Header = () => {
    return <div className="header">
        <Link to={"/"}>
            <img src={logo} alt={"Phylazonit NG"}/>
        </Link>
        <h1 className="title">Tarlókalkulátor</h1>
    </div>;
}
export default Header;