import {createSlice} from "@reduxjs/toolkit";

const SiteSlice = createSlice({
    name: "site",
    initialState: {
        plants: [
            {id: 'maize', name: 'Kukorica'},
            {id: 'wheat', name: 'Búza'},
            {id: 'autumnBarley', name: 'Őszi árpa'},
            {id: 'springBarley', name: 'Tavaszi árpa'},
            {id: 'rye', name: 'Rozs'},
            {id: 'oats', name: 'Zab'},
            {id: 'sunflower', name: 'Napraforgó'},
            {id: 'rape', name: 'Repce'}
        ],
        plantNames:{
            maize: 'Kukorica',
            wheat: 'Búza',
            autumnBarley: 'Őszi árpa',
            springBarley: 'Tavaszi árpa',
            rye: 'Rozs',
            oats: 'Zab',
            sunflower: 'Napraforgó',
            rape: 'Repce',
        },
        amount:0,
    },
    reducers: {
        amount(state, action) {
            state.amount = action.payload;
        },
    },
});

export const siteActions = SiteSlice.actions;
export default SiteSlice;
